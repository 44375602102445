<template>
<div class="">
  <Table v-cloak :fields="fields" :items="items" :busy.sync="loading">
    <template #cell(name)="data">
      <div style="min-width: 150px">
        <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ $t(data.item.name) }}</span>
      </div>
    </template>
    <template #cell(variables)="data">
      <div style="min-width: 150px">
        <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.variables.join(", ") }}</span>
      </div>
    </template>
    <template #cell(actions)="data">
      <div style="min-width: 80px">
        <router-link
          :to="{ name: 'adminTemplatesEdit', params: { id: $route.params.id, template_id: data.item.uuid }}"
          class="btn btn-icon btn-light-info btn-sm mr-3"
        >
          <span class="svg-icon btn-light-primary">
            <inline-svg src="/media/svg/icons/Communication/Write.svg" />
          </span>
        </router-link>
        <div class="btn btn-icon btn-light-danger btn-sm" @click="onDelete(data.item)">
          <span class="svg-icon svg-icon-md btn-light-info">
            <inline-svg src="/media/svg/icons/Home/Trash.svg" />
          </span>
        </div>
      </div>
    </template>
  </Table>
  <div v-if="totalCount && params.pagination.limit < totalCount" class="mt-3">
    <Paginations v-model="params.pagination.page"
                 :total-rows="totalCount"
                 :per-page="params.pagination.limit"
    />
  </div>
</div>
</template>
<script>
import Table from '@/components/UI/Table'
import Paginations from '@/components/UI/Paginations'
import Repo from '@/core/repository/admin/templatesRepository'
import baseList from '@/mixins/baseList'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'TemplateList',
  components: {
    Paginations,
    Table, 
  },
  mixins: [baseList],
  data() {
    return {
      params: {
        pagination: {
          page: 1,
          limit: 100,
        },
        filter: {
          company: this.$route.params.id,
        },
      },
      fields: [
        {
          key: 'name',
          label: this.$t('TEMPLATES.NAME'),
          sortable: false,
        },
        {
          key: 'variables',
          label: this.$t('TEMPLATES.VARIABLES'),
          sortable: false,
        },
        {
          key: 'actions',
          label: this.$t('BASE.ACTIONS'),
          class: 'text-right',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    Repo() {
      return Repo
    },
  },
  watch: {
    search(v) {
      this.params.search = v
      if (!v) delete this.params.search
      this.reFetchData()
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PAGE.TEMPLATES') }])
  },
  methods: {
    fetchData() {
      this.loading = true
      this.params.order = {}
      this.Repo.list(this.params).then(({ data }) => {
        this.items = data.payload.items
        this.totalCount = data.payload.pagination.totalCount
        this.loading = false
      })
    },
  },
}
</script>
